<template>
  <div>

    <el-dialog
      v-model="showEvaluationForm" v-if="currentEvaluation">
      <h5>{{fullInfo}}</h5>

      <el-row class="mt-4">
        <el-col :span="7">
          Datum
          <el-date-picker
            class="w-100"
            v-model="currentEvaluation.date"
            type="date"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            placeholder="Datum auswählen">
          </el-date-picker>
        </el-col>
        <el-col :span="3" :offset="1">
          Gewicht
          <el-input v-model="currentEvaluation.weight" class="w-75" placeholder="Gewicht"></el-input> kg
        </el-col>
        <el-col :span="2" :offset="1">
          ECOG
          <el-input v-model="currentEvaluation.ecog" class="w-75"></el-input>
        </el-col>
      </el-row>

      <template #footer>
        <el-row>

          <el-col :span="10" class="text-left">
            <el-popconfirm
              v-if="currentEvaluation.id"
              title="Sie möchten diesen Eintrag löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="onDeleteEvaluation">
              <template #reference>
                <el-button type="danger">Löschen</el-button>
              </template>
            </el-popconfirm>&nbsp;
          </el-col>
          <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="showEvaluationForm = false">Abbrechen</el-button>
            <el-button type="primary" @click="onSaveEvaluation">Eintrag speichern</el-button>
          </el-col>

        </el-row>

      </template>
    </el-dialog>

    <el-card>

    <el-row class="mt-1 mb-4">
      <el-col :span="24">

        <el-table
            ref="evaluationstable"
            :data="patientEvaluations"
            style="width: 100%"
            highlight-current-row
            @row-click="onEvaluationClick"
            stripe>
          <el-table-column
            prop="date"
            label="Datum"
            width="200">
            <template #default="scope">
              <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="200"
            prop="weight"
            label="Gewicht">
          </el-table-column>
          <el-table-column
              prop="ecog"
              label="ECOG">
          </el-table-column>
          <el-table-column
              width="150">
            <template #default="scope">
              <div class="text-right">
                <el-button size="mini" type="warning" @click="onEvaluationClick(scope.row)" icon="el-icon-edit"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-row class="mt-2">
          <el-col :span="6">Neuer Eintrag</el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="3">
            Datum
            <el-date-picker
              size="small"
              class="w-100"
              v-model="newEvaluation.date"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
          <el-col :span="3" :offset="1">
            Gewicht
            <el-input v-model="newEvaluation.weight" class="w-75" placeholder="Gewicht"></el-input> kg
          </el-col>
          <el-col :span="2" :offset="1">
            ECOG
            <el-input v-model="newEvaluation.ecog" class="w-75"></el-input>
          </el-col>
          <el-col :span="4" class="mt-4">
            <el-button type="primary" @click="onAddEvaluation">Eintrag hinzufügen</el-button>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
    </el-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "PatientEvaluation",
  props: ['reload'],
  components: {
  },
  data() {
    return {
      currentEvaluationId: null,
      patientEvaluations: [],
      currentEvaluation: null,
      showEvaluationForm: false,
      newEvaluation: {
        date: moment().format("YYYY-MM-DD"),
        weight: '',
        ecog: ''
      }
    }
  },
  mounted: async function () {
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
  },
  watch: {
    'reload': async function() {
      if(this.currentPatient) {
        this.newEvaluation.patient_id = this.currentPatient.id
        this.loadEvaluations()
      }
    }
  },
  methods: {
    ...mapActions('patients', ['getEvaluationsOfPatient', 'deleteEvaluationOfPatient', 'saveEvaluationToPatient']),
    async loadEvaluations() {
      this.patientEvaluations = await this.getEvaluationsOfPatient( { patient_id: this.currentPatient.id } )
    },
    onEvaluationClick(row) {
      this.currentEvaluation = row
      this.showEvaluationForm = true
    },
    async onAddEvaluation() {
      if(!this.newEvaluation.date) {
        this.$message({
          message: "Bitte wählen Sie ein Datum",
          type: 'error'
        });
        return
      }

      if(!this.newEvaluation.weight && !this.newEvaluation.ecog) {
        this.$message({
          message: "Bitte geben Sie ein Gewicht oder ECOG an",
          type: 'error'
        });
        return
      }
      await this.saveEvaluationToPatient(this.newEvaluation)
      await this.loadEvaluations()

      this.newEvaluation = {
        date: moment().format("YYYY-MM-DD"),
        weight: '',
        ecog: '',
        patient_id: this.currentPatient.id
      }
    },
    async onSaveEvaluation() {
      if(!this.currentEvaluation.date) {
        this.$message({
          message: "Bitte wählen Sie ein Datum",
          type: 'error'
        });
        return
      }

      if(!this.currentEvaluation.weight && !this.currentEvaluation.ecog) {
        this.$message({
          message: "Bitte geben Sie ein Gewicht oder ECOG an",
          type: 'error'
        });
        return
      }
      await this.saveEvaluationToPatient(this.currentEvaluation)
      this.showEvaluationForm = false
      await this.loadEvaluations()
    },
    async onDeleteEvaluation() {
      await this.deleteEvaluationOfPatient(this.currentEvaluation)
      this.showEvaluationForm = false
      this.currentEvaluation = null
      await this.loadEvaluations()
    }
  }
}
</script>
<style scoped>
</style>
